<template>
  <div class="lean-improvement-batch-wrapper py-2" v-if="game">
    <div class="columns">
      <div class="column">
        <h3 class="title is-6 mb-2 has-text-centered">One piece flow:</h3>
        <div class="buttons mt-5 is-centered">
          <b-button type="is-info" @click="updateOne" rounded
            >Update batch sizes to 1 unit</b-button
          >
        </div>
      </div>
      <div class="column is-narrow">
        <div class="is-divider-vertical"></div>
      </div>
      <div class="column">
        <h3 class="title is-6 mb-2">Or customize for each product:</h3>
        <b-field v-for="temp in templates" :key="temp.id" grouped>
          <p class="control pr-3 pt-2">
            <b>{{ temp.name }}:</b>
          </p>
          <b-input
            type="number"
            :min="1"
            :max="20"
            v-model="temp.batchSize"
          ></b-input>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeanImprovementBatch",
  props: {
    game: Object
  },
  computed: {
    templates() {
      return Object.values(this.newTemplates);
    }
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    updateOne() {
      this.templates.forEach(temp => {
        temp.batchSize = 1;
      });
    },
    initializeData() {
      this.readyWatch = false;
      this.newTemplates = JSON.parse(
        JSON.stringify(this.game.params.productsTemplates)
      );
      setTimeout(() => {
        this.readyWatch = true;
      }, 5);
    }
  },
  watch: {
    newTemplates: {
      handler: function() {
        if (this.readyWatch) {
          this.$emit("update", { type: "batch", obj: this.newTemplates });
        }
      },
      deep: true
    }
  },
  data() {
    return {
      readyWatch: false,
      newTemplates: {}
    };
  }
};
</script>

<style lang="scss"></style>
